import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const TopFiveAnime = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="Top 5 Anime of All Time" />
        <meta
          name="twitter:image"
          content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div className="article">
        <Link to="/shoyaright">
          <div className="shoyarightChomskyArticle" />
        </Link>
        <div className="articleTitle">
          <p>Top 5 Anime of All Time</p>
        </div>
        <div className="byLine">
          <p> By: Mikalov Bandolov, Guest Contributor (Pub. Mar. 5, 2021) </p>
        </div>
        <div className="articleBody">
          <p>
            <span className="highlight">Warning!</span> This article contains
            minor spoilers.
            <br />
            <span className="editorsNote">
              Notes from the Editor-in-Chief appear in yellow.
            </span>
          </p>
          <p></p>
          <p>
            As everyone’s favorite anime expert, I’ve long been asked to put
            together an irrefutable list of the greatest anime of all time.
            After an impromptu request by the publisher of this website and
            about 5 minutes of research, I’ve decided that now{" "}
            <span className="editorsNote">
              (LOL 'now' refers to last April)
            </span>{" "}
            is as good a time as any to dead this debate once and for all. All
            debates on this topic will cease to exist and unfortunately, about
            5% of YouTube will need to find new content to create.{" "}
            <span className="editorsNote">
              (Bro said 'Get Out the Robot' lmaooo.)
            </span>{" "}
            Without further ado, let's begin.
          </p>
          <p>
            <span className="highlight">
              NO. 5: FULLMETAL ALCHEMIST: BROTHERHOOD
            </span>
          </p>
          <p>
            Some people believe this show to be trash. Don’t listen to those
            people as they are objectively wrong and do not know what they are
            talking about. <i>Fullmetal Alchemist: Brotherhood</i> is nothing
            short of a masterpiece. It tells the story of two brothers who
            commit the cardinal sin in Alchemy and then spend the rest of the
            show dealing with the consequences. The backdrop of their coming of
            age story is a political crisis, war, racism, and all that other
            good bad shit that makes you reflect on the real world. It’s like
            Harry Potter but the characters are actually boxing, dealing with
            tragedy, and developing into better people{" "}
            <span className="editorsNote">
              (this shit ain't nothing like no Harry Potter what tip is you on
              lmao)
            </span>
            . Also, God pulls up a lot. Also, Scar is an all-time Black anime
            character and wants to collapse the State.{" "}
            <span className="editorsNote">(I stamp it.)</span> Yeah this show
            top 5.
          </p>
          <p>
            <span className="highlight">NO. 4: SHINGEKI NO KYOJIN</span>
          </p>
          <p>
            This show so heat I had to address it by its real name{" "}
            <span className="editorsNote">(I'm ctfu)</span>. Otherwise known as{" "}
            <i>Attack on Titan</i>, <i>Shingeki no Kyojin</i> is a show about a
            bunch of giants called Titans eating people. And now humanity is
            almost extinct. Every episode you’re thinking 'is my favorite
            character about to get smoked today'? Fire. Also, Mikasa is in this
            show and she might be the greatest anime character ever. Also, Eren
            eventually becomes the 2nd greater anime character ever. I’m pretty
            sure by the time the final season comes out this show is gonna take
            the top spot.{" "}
            <span className="editorsNote">
              (Bro...do you not know what 'all time' means how tf is the list
              gone change smfh. I agree though this jawn off the meter.)
            </span>
          </p>
          <p>
            <span className="highlight">NO. 3: CODE GEASS</span>
          </p>
          <p>
            I re-watched this show recently and remembered how damn good it is.
            The Holy Brittanian Empire (America) decided to do that imperialism
            thing and my guy Lelouch was like fuck no. He becomes a resistance
            leader and you basically watch him as he takes down an empire. Also
            there’s mechs and a Sharingan in this show{" "}
            <span className="editorsNote">
              (you be loving the mechs lmao, I can't even comment on this jawn
              fr I'm not hip)
            </span>
            . If that doesn’t convince you this show is top 3 there’s no saving
            you.
          </p>
          <p>
            <span className="highlight">NO. 2: PUELLA MAGI MADOKA MAGICA</span>
          </p>
          <p>
            <span className="editorsNote">
              (I KNEW YOU WAS GON PUT THIS BS ON HERE LMFAOOOO)
            </span>{" "}
            If you want to talk about high quality television, look no further
            than <i>Magica Madoka</i>. The show is about girls that fight
            witches. Sounds trash right?{" "}
            <span className="editorsNote">
              (It really don't but it is though.)
            </span>{" "}
            Wrong! This show is a damn masterpiece. You go into the show
            thinking its one of those slice of life anime but boy does it turn
            up quick. Constant drama, heartbreak, boxing, plot twists, and
            amazing character development neatly packed into 12 episodes{" "}
            <span className="editorsNote">
              (naw matter fact you selling it I might gotta change my tune we'll
              see lol)
            </span>{" "}
            make this one of the best stories you’ll consume on any medium.
            Watch it. Forreal. You won’t regret it.
          </p>
          <p>
            <span className="highlight">HONORABLE MENTIONS</span>
          </p>
          <p>
            <span className="editorsNote">
              Imma just list these w/o comment bro cuz it's getting long lol:{" "}
            </span>{" "}
            <i>Demon Slayer</i>, <i>Hunter x Hunter</i>, <i>Samurai Champloo</i>
            , <i>Vinland Saga</i>, <i>One Piece</i>, <i>Monster</i>,{" "}
            <i>JoJo's Bizarre Adventure</i>, <i>Kakegurui</i>.
          </p>
          <p>
            <span className="highlight">NO. 1: STEINS;GATE</span>
          </p>
          <p>
            <i>Steins;Gate</i> might be the best show I’ve ever watched (up
            there with <i>The Wire</i> and <i>GOT</i> Seasons 1-6).{" "}
            <span className="editorsNote">
              (I couldn't get past the first episode. Imma give it another shot
              but I feel like you gassing it.)
            </span>{" "}
            Even giving you guys a description about the show here really
            wouldn’t do it justice. Just know it's about space and time and
            relativity and life. This is one of those shows that after you watch
            it the first time, you watch it again and see all the easter eggs
            and clues the writers of the show left for you. 10/10 all the way
            through and I promise your life will be significantly better after
            you watch it. If you’re still reading this post and not looking for
            a place to watch this show you’re doing something wrong.{" "}
            <span className="editorsNote">
              (Naw now you joe'd it I'm not even watching it now.)
            </span>
          </p>
          <p>
            This isn’t an opinion piece and any complaints (for whatever weird
            reason) can be addressed to Zuff.{" "}
            <span className="editorsNote">
              (LMAO love bro thanks for lending your expertise fr.)
            </span>
          </p>
        </div>
        <p className="plug">
          If you enjoyed this article, consider{" "}
          <a href="https://www.patreon.com/join/shoyaright?">
            becoming a patron
          </a>{" "}
          or making a{" "}
          <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=zuff@shoya.co&lc=US&item_name=The%20Shoyaright%21&currency_code=USD&no_note=0&bn=PP-DonationsBF:btn_donateCC_LG.gif:NonHostedGuest">
            one-time pledge
          </a>
          . Support corporate journalism!
        </p>
        <div className="articleCopyright">
          <p>&copy; Shoya, Inc. 2021</p>
        </div>
      </div>
    </div>
  )
}
export default TopFiveAnime
